
import { defineComponent } from "vue";
// import Footer from "../components/Footer.vue";
// import Menu from "../components/Menu.vue";

export default defineComponent({
  name: "Impressum",
  // props: {
  //   msg: String,
  // },
  //components: { Menu, Footer },
});
