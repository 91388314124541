<template>
  <Menu />
  <!-- <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div> -->
  <router-view />
  <Footer />
</template>

<script lng="ts">
import { defineComponent } from "vue";

import Footer from "@/components/Footer.vue";
import Menu from "@/components/Menu.vue";

export default defineComponent({
  name: "App",
  components: { Menu, Footer },
});
</script>

<style>
@import "./assets/css/global.css";
</style>
