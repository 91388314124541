import { createApp } from 'vue'
import App from './App.vue'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import router from './router'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret, faAngleDown, faAngleLeft, faCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import AosVue from "aos-vue";
import cookieConsent from 'bootstrap-cookie-consent-settings'; //https://www.npmjs.com/package/bootstrap-cookie-consent-settings

library.add(faUserSecret, faAngleDown, faAngleLeft, faCircle)

createApp(App)
    .use(router)
    .use(AosVue)
    .provide('cookieConsent', cookieConsent)
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app')
