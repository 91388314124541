<template>
  <HomeContent />
</template>

<script >
// @ is an alias to /src
import HomeContent from "@/components/HomeContent.vue";

// import Component from "vue-class-component";
// Component.registerHooks(["beforeRouteLeave"]);
// import { Options, Vue } from "vue-class-component";
//import Vue from "vue";

//Vue.registerHooks(["beforeRouteLeave"]);

export default {
  inject: { cookieConsent: "cookieConsent" },
  name: "Home",
  components: {
    HomeContent,
  },
  // beforeRouteLeave(to, from, next) {
  //   // console.log("to:" + to.name);
  //   // console.log("from:" + from.name);
  //   console.log("beforeRouteLeave");

  //   const menu = document.querySelector(".menu-block");
  //   menu.classList.toggle("active");
  //   document.querySelector(".menu-overlay").classList.toggle("active");

    // if (menu.classList.contains("active")) {
    //   console.log("remove menu active");
    //   menu.classList.remove("active");
    //   document.querySelector(".menu-overlay").classList.remove("active");
    //   //menu.classList.toggle("active");
    // }
    // if (document.querySelector(".menu-overlay").classList.contains("active)")) {
    //   console.log("remove menu-overlay active");
    //   document.querySelector(".menu-overlay").classList.remove("active");
    // }
    // //document.querySelector(".menu-overlay").classList.toggle("active");

  //   next();
  // },
};
</script>

 
